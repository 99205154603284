import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import PageHeader from "components/PageHeader/index";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import BreakPar from "pages/Blogs/SingleArticle/sections/BreakPar";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
import footerRoutes from "footer.routes";

function SingleArticle() {
  const [aboutDetails, setAboutDetails] = useState({});
  const [abt2, setAbt2] = useState("");
  const [abt3, setAbt3] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://seashell-app-jw7mf.ondigitalocean.app/api/about?populate=*`
        );
        const data = await response.json();
        setAboutDetails(data.data.attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setAbt2(aboutDetails.a2_pic?.data?.attributes?.url);
        setAbt3(aboutDetails.a3_pic?.data?.attributes?.url);
      }
    };

    fetchData();

    console.log(aboutDetails);
  }, [loading]);

  if (loading) {
    return <div>Loading...</div>; // Replace with a spinner or loading component if needed
  }

  return (
    <>
      <PageHeader img="about" />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: "20",
        }}
      >
        <Information
          label={aboutDetails.a1_high || "How We Started"}
          title={aboutDetails.a1_title || "The JT's Manukan Grille Origin Story"}
          description={
            aboutDetails.a1_desc ||
            "For two decades and counting, JT’s Manukan Grille has been serving grilled chicken items marinated the Illongo way. “JT’s”, as what most patrons would have it, is the brainchild of actor Joel Torre and his wife, Cristy. It all began when the couple started experimenting with various marinades and recipes passed down from their parents and aunts. And while the desire to share their delicacy with others burned fiercely, turning it into a business was also inevitable."
          }
        />
        <BreakPar
          label={aboutDetails.a2_high || "The Founder"}
          img={abt2}
          title={aboutDetails.a2_title || "CELEBRITY TURNS RESTAURATEUR"}
          description={
            aboutDetails.a2_desc ||
            "Joel and his wife Cristy Azcona-Torre decided to turn entrepreneurs, opening the first JT’s Manukan Grille in 2003. Naturally, they chose Bacolod’s most famous recipe – the chicken inasal – as their signature dish, and they were not mistaken. When US celebrity chef Andrew Zimmern proclaimed Filipino food as the next big thing in 2012, he listed the inasal among the Philippines’ iconic foods."
          }
        />
        <BreakPar
          label={aboutDetails.a3_high || "Our Vision"}
          img={abt3}
          title={aboutDetails.a3_title || "TODAY THE PHILIPPINES, TOMORROW THE WORLD"}
          description={
            aboutDetails.a3_desc ||
            "Today, JT’s Manukan Grille has 40+ branches nationwide with the goal to explore opportunities globally and grow locally. With 20+ years of serving the delectable inasal chicken, the company continues to evolve. It is now gearing towards a more modern and industrial look to cater to the younger demographics as evident by the new logo and signage that can be seen in some branches."
          }
        />
        <Posts />
        <Support />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SingleArticle;
