import { useState, useEffect } from "react";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";

// SwiperJS
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import PropTypes from "prop-types";
import MKBox from "components/MKBox";

import bgImage from "assets/images/slide1.webp";
import bgImage2 from "assets/images/slide2.webp";
import bgImage3 from "assets/images/land-2.webp";
import bgImage4 from "assets/images/land-1.webp";
// import branch from "assets/images/bacolod.webp";
// import menu from "assets/images/menuHead.webp";
import menuImg from "assets/images/jtsBanner.webp";
// import mobileHead from "assets/images/JTs-Mobile-Banner.webp";

import { Autoplay, Virtual } from "swiper";

function HeaderTwo({ img }) {
  const [jumbo, setJumbo] = useState(bgImage);
  const [sliderImages, setSliderImages] = useState([bgImage, bgImage2, bgImage3, bgImage4]);
  const [headerImages, setHeaderImages] = useState(null);
  const [loading, setLoading] = useState(true);
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("sm"));

  // Fetch data
  useEffect(() => {
    // console.log(img);
    const fetchData = async () => {
      try {
        const sliderResponse = await fetch(
          `https://seashell-app-jw7mf.ondigitalocean.app/api/slider-image?populate=%2A&pagination[limit]=100`
        );
        const sliderData = await sliderResponse.json();
        setSliderImages(sliderData.data.attributes.image.data.map((sl) => sl.attributes.url));
      } catch (error) {
        console.error("Error fetching slider images:", error);
      }

      // Fetch header images
      try {
        const headerResponse = await fetch(
          `https://seashell-app-jw7mf.ondigitalocean.app/api/jumbos?populate=*`
        );
        const headerData = await headerResponse.json();
        setHeaderImages(headerData);
      } catch (error) {
        console.error("Error fetching header images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // console.log(sliderImages);
    // console.log(headerImages);
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      let newJumbo = menuImg; // Default for `img !== "landing"`

      headerImages.data.forEach((i) => {
        const { name } = i.attributes;
        const { url } = i.attributes.src.data.attributes;

        // Match `img` to specific `name` and update `newJumbo`
        if (img === "about" && name === "about") {
          newJumbo = url;
        } else if (img === "menu" && name === "menu") {
          newJumbo = url;
        } else if (img === "branches" && name === "branches") {
          newJumbo = url;
        } else if (img === "catering" && name === "catering") {
          newJumbo = url;
        }
      });
      // console.log(newJumbo);
      setJumbo(newJumbo); // Update the state only once
    }
  }, [loading, img, headerImages]);

  // Render slider for "landing" images
  const sliderGallery = sliderImages.map((sl) => (
    <SwiperSlide>
      <MKBox
        key={sl}
        component="img"
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          zIndex: 10,
          width: "100%",
          height: "100%",
          objectFit: { xs: "contain", md: "cover" },
          objectPosition: "center",
        }}
        alt="Slider Image"
        src={sl}
      />
    </SwiperSlide>
  ));

  if (img === "landing") {
    return (
      <MKBox component="header" height={{ xs: "250px", md: "400px", lg: "700px" }}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Autoplay, Virtual]}
          autoplay={{ delay: 4000 }}
          speed={700}
          style={{ width: "100%", height: "100%" }}
        >
          {sliderGallery}
        </Swiper>
      </MKBox>
    );
  }

  return (
    <MKBox component="header" position="relative" height={{ xs: "250px", md: "80vh" }} width="100%">
      <MKBox
        component="img"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
          width: "100%",
          objectPosition: jumbo === menuImg ? "top" : "center",
          objectFit: "cover",
          backgroundColor: "#faf3e6",
        }}
        alt="JTs Logo"
        src={jumbo}
      />
    </MKBox>
  );
}

HeaderTwo.propTypes = {
  img: PropTypes.string.isRequired,
};

export default HeaderTwo;
