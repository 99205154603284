import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import Information from "./Information";

function OurEfforts({ label, title, description, img }) {
  return (
    <MKBox component="section">
      <MKBox
        component="img"
        position="relative"
        sx={{
          width: "100%",
          height: { xs: "15rem", md: "35rem" },
          objectFit: "cover",
          objectPosition: "center 25%",
        }}
        alt="JTs Logo"
        src={img}
      />
      <Information label={label} title={title} description={description} />
    </MKBox>
  );
}

// Setting default values for the props of MKAlert
OurEfforts.defaultProps = {};

// Typechecking props of the OurEfforts
OurEfforts.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default OurEfforts;
